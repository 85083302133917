<template>
  <b-container fluid>
    <b-row class="heading_alignment">
      <b-col md="12">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              {{cvCardTitle}}
            </h4><br>
          </template>
          <template>
            <b-row class="w-100 mb-3">
              <b-col class="col-12 col-sm-4 col-md-3 col-lg-3 mb-2">
              <!-- Backend Search -->
              <input class="form-control"
                  id="search"
                  v-model="whereFilter.search_param"
                  type="search"
                  @input="searchUserWithParams"
                  :placeholder="cvSearchText" />
              <!-- Backend Search -->
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="userObjList && userObjList.length > 0">
                  <b-row>
                    <div v-for="(userObj,index) in userObjList" :key="index" class="mt-5 col-12 col-sm-6 col-md-4 col-lg-3">
                      <iq-card className="iq-card-block iq-card-stretch side_border">
                        <template v-slot:body>
                          <div class="iq-badges text-left" @click="goToUserProfile(userObj)" style="cursor: pointer;">
                            <div class="badges-icon">
                              <b-avatar style="border-radius: 100%!important;" class="avatar-80 rounded" v-if="userObj.image">
                                <img :src="userObj.image" class="avatar-80 rounded" alt="UserProfilePicture">
                              </b-avatar>
                               <b-avatar v-else class="avatar-80" :text="getFirstLetterOfAString(userObj.user_name)" >
                              </b-avatar>
                            </div>
                            <h5 class="mb-2 title twoLineOnly" :title="userObj.user_name">
                              <span v-if="userObj.user_name">{{userObj.user_name}}</span>
                              <span v-else>---------</span>
                            </h5>
                          </div>
                          <span class="mr-2 font-weight-normal pull-right" variant="primary">
                          {{userObj.user_city}}, {{ userObj.user_state }}
                          </span>
                        </template>
                      </iq-card>
                    </div>
                  </b-row>
              </b-col>
              <b-col class="container_minheight" md="12" v-else>
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" md="12" class="my-1">
                <b-row>
                  <b-col sm="12" md="7">
                    <b-btn variant="primary" @click="loadMoreData" v-if="showLoadMoreBtn">Load More</b-btn>
                  </b-col>
                  <b-col sm="5" md="5">
                    <b-pagination
                      v-model="currentBatchNo"
                      :total-rows="totalRows"
                      :per-page="dataPerPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { User } from "../../../FackApi/api/user.js"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"

export default {
  name: "UserFilterList",

  data () {
    return {
      cvCardTitle: "Users",
      cvSearchText: "Type to Search",
      toastMsg: null,
      toastVariant: "default",
      showModelUserEdit: false,
      showModelUserDelete: false,
      userObjList: [],
      userEditObj: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      delObj: null,
      profileCompleted: null,
      whereFilter: {
        search_param: ""
      },
      currentBatchNo: 1,
      dataPerPage: 25,
      showLoadMoreBtn: true,
      vmAddSubscriberFormData: Object.assign({}, this.initFormData())
    }
  },
  beforeMount () {
    // this.cvUserData = this.$route.params.user_data
  },
  async mounted () {
    this.whereFilter.search_param = this.$route.params.user_data
    socialvue.index()
    this.userList()
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  methods: {
    /**
     * Get User Status
     */
    getUserStatus (status) {
      switch (status) {
        case 10:
          return "BLOCKED"
        case 9:
        default:
          return "ACTIVE"
      }
    },
    /**
     * initFormData
     */
    initFormData () {
      return {
        "user_email": "",
        "subs_plan_id": ""
      }
    },
    /**
    * getFirstLetterOfAString
    */
    getFirstLetterOfAString (string) {
      if (string) {
        return string.substr(0, 1)
      }
    },
    /**
     * loadMoreData
     */
    async loadMoreData () {
      this.currentBatchNo = this.currentBatchNo + 1
      this.userList(true)
    },
    /**
     *
     * Redirect to User Profile
     */
    goToUserProfile (user) {
      try {
        window.open(`/user/${user.user_id}`)
      }
      catch (err) {
        console.error("Exception occurred at UserList() and Exception:", err.message)
      }
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      if (this.whereFilter.search_param.length <= 3) {
        return
      }
      this.currentBatchNo = 1
      await this.userList()
    },
    /**
     *  Do Copy
     */
    doCopy: function (msg) {
      var dummy = document.createElement("textarea")

      document.body.appendChild(dummy)
      // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
      dummy.value = msg
      dummy.select()
      document.execCommand("copy")
      document.body.removeChild(dummy)
      console.log("Msg Copy")

      // this.snackbar = true;
      // this.err_msg = 'Copied';
    },
    /**
     *  initUserBlockData
     */
    initUserBlockData () {
      return {
        user_id: "",
        curr_user_id: ""
      }
    },
    /**
    * userList
    */
    async userList () {
      try {
        if (this.whereFilter.search_param.length < 1) {
          return
        }
        let userListResp = await User.userListSearch(this, this.whereFilter)
        if (userListResp.resp_status) {
          this.userObjList = userListResp.resp_data.data
          this.totalRows = userListResp.resp_data.count
        }
        else {
          this.userObjList = []
          this.toastMsg = userListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at userList() and Exception:", err.message)
      }
    },
    /**
     * showUserDeleteDialog
     */
    showUserDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserDeleteDialog() and Exception:", err.message)
      }
    },
    /**
     * userDelete
     */
    async userDelete () {
      try {
        let userDelResp = await User.userDelete(this, this.delObj.user_id)
        await ApiResponse.responseMessageDisplay(this, userDelResp)
        if (userDelResp && !userDelResp.resp_status) {
          this.showModelUserDelete = false
          return false
        }
        let index = this.userObjList.indexOf(this.delObj)
        this.userObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUserDelete = false
      }
      catch (err) {
        console.error("Exception occurred at userDelete() and Exception:", err.message)
      }
    },
    closeUserEditModal () {
      try {
        this.showModelUserEdit = false
      }
      catch (err) {
        console.error("Exception occurred at closeUserEditModal() and Exception:", err.message)
      }
    }
  }
}
</script>
<style>
.twoLineOnly{
  max-height: 50px !important;
  min-height: unset !important;
}
.iq-card.iq-card-block.iq-card-stretch.side_border{
  padding-left:10px ;
}
.badges-icon{
  margin-bottom: 5px !important;
}
</style>
